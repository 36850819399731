import axios from 'axios';
import { axiosDefault, axiosHeaders } from '../_helpers/auth-header';
import { config } from '../_helpers/config';

export const getRouteList = (siteMode, wsId, serviceDay, sandboxId) => {
    let userInfo = JSON.parse(window.sessionStorage['userInfo']);
    let accessToken = userInfo ? userInfo.accessToken : '';
    let url = config.ROApi + `workspaces/${wsId}/routes?funcId=209&service_day=${serviceDay}`;
    if (siteMode === 'PLANNER') {
        url = config.ROApi + `workspaces/${wsId}/Sandbox/${sandboxId}/days/${serviceDay}/routes`;
    }
    let headers = {
        Authorization: 'Bearer ' + accessToken,
        Accept: 'application/json',
    };
    return axiosDefault.get(url, { headers });
};

export const getWorkspaceExtent = (wsId, serviceDay, route) => {
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    let url = config.ROApi + `workspaces/${wsId}/workspaceExtent?funcId=209`;
    if (serviceDay !== null) {
        url += `&serviceDay=${serviceDay}`;
    }
    if (route) {
        url += `&route=${route}`;
    }
    const headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.get(url, { headers });
};

export const getSelectedRouteExtent = (wsId, serviceDay, selectedRoutes, siteMode, project) => {
    let userInfo = JSON.parse(window.sessionStorage['userInfo']);
    let accessToken = userInfo ? userInfo.accessToken : '';
    let url = config.ROApi + `workspaces/${wsId}/servicelocations/Extent?`;
    let routes = selectedRoutes;
    url += `service_day=${serviceDay}`;
    if (siteMode === 'PLANNER') {
        url += `&sandbox_id=${project}`;
    }
    const headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.post(url, routes, { headers });
};

export const getFacilities = (siteMode, wsId, project) => {
    let url = config.ROApi + `workspaces/${wsId}/facilities`;
    if (siteMode === 'PLANNER') {
        url = config.ROApi + `workspaces/${wsId}/sandbox/${project}/facilities`;
    }
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.get(url, { headers });
};

export const addFacility = (wsId, data, siteMode, project) => {
    let url = config.ROApi + 'workspaces/' + wsId + '/facilities';
    if (siteMode === 'PLANNER') {
        url = config.ROApi + `workspaces/${wsId}/sandbox/${project}/facilities`;
    }
    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
    };
    return axiosDefault.post(url, data, { headers });
};

export const updateFacility = (wsId, gid, data, siteMode, project) => {
    let url = config.ROApi + 'workspaces/' + wsId + '/pwfacilities/' + gid + '/labels';
    if (siteMode === 'PLANNER') {
        url = config.ROApi + `workspaces/${wsId}/sandbox/${project}/pwfacilities/${gid}/labels`;
    }
    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
        'content-type': 'application/json; charset=utf-8',
    };
    return axios({
        method: 'PATCH',
        url,
        data: data,
        headers,
    });
};

export const deleteFacility = (workspaceId, gid, siteMode, project) => {
    let url = config.ROApi + 'workspaces/' + workspaceId + '/facilities/' + gid;
    if (siteMode === 'PLANNER') {
        url = config.ROApi + `workspaces/${workspaceId}/sandbox/${project}/facilities/${gid}`;
    }
    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
    };
    return axiosDefault.delete(url, { headers });
};

export const getSideOfStreet = (streetLinkId, streetX, streetY) => {
    const url = config.ROApi + 'streets/segment/' + streetLinkId + '/x/' + streetX + '/y/' + streetY + '/Side';
    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
        'content-type': 'application/json',
    };
    return axios({
        method: 'GET',
        url,
        headers,
    });
};

export const pinFacility = (wsId, fcGid, streetLinkId, streetX, streetY, streetSide, siteMode, project) => {
    var dataStr = [
        { op: 'replace', path: '/LinkId', value: streetLinkId },
        { op: 'replace', path: '/X', value: streetX },
        { op: 'replace', path: '/Y', value: streetY },
        { op: 'replace', path: '/Score', value: -1 },
        { op: 'replace', path: '/Side', value: streetSide },
        { op: 'replace', path: '/Status', value: '' },
    ];
    let url = config.ROApi + 'workspaces/' + wsId + '/facilities/' + fcGid;
    if (siteMode === 'PLANNER') {
        url = config.ROApi + `workspaces/${wsId}/sandbox/${project}/facilities/${fcGid}`;
    }
    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
        'content-type': 'application/json; charset=utf-8',
    };
    return axios({
        method: 'PATCH',
        url,
        data: JSON.stringify(dataStr),
        headers,
    });
};

export const unpinFacility = (wsId, fcGid, siteMode, project) => {
    let dataStr = [
        { op: 'replace', path: '/LinkId', value: 0 },
        { op: 'replace', path: '/X', value: null },
        { op: 'replace', path: '/Y', value: null },
        { op: 'replace', path: '/Score', value: 0 },
        { op: 'replace', path: '/Side', value: '' },
        { op: 'replace', path: '/Status', value: 'U' },
    ];
    let url = config.ROApi + 'workspaces/' + wsId + '/facilities/' + fcGid;
    if (siteMode === 'PLANNER') {
        url = config.ROApi + `workspaces/${wsId}/sandbox/${project}/facilities/${fcGid}`;
    }
    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
        'content-type': 'application/json; charset=utf-8',
    };
    return axios({
        method: 'PATCH',
        url,
        data: JSON.stringify(dataStr),
        headers,
    });
};

export const flipFacilities = (wsId, selGids, siteMode, project) => {
    let url = config.ROApi + 'workspaces/' + wsId + '/facilities/flip';
    if (siteMode === 'PLANNER') {
        url = config.ROApi + `workspaces/${wsId}/sandbox/${project}/facilities/flip`;
    }
    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
        'content-type': 'application/json',
    };
    return axios({
        method: 'POST',
        url,
        data: JSON.stringify(selGids),
        headers,
    });
};

export const getServiceLocations = (wsId, serviceDay, siteMode, project) => {
    let userInfo = JSON.parse(window.sessionStorage['userInfo']);
    let accessToken = userInfo ? userInfo.accessToken : '';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    let url = config.ROApi + `workspaces/${wsId}/days/${serviceDay}/serviceLocations?allfields=false`;
    if (siteMode === 'PLANNER') {
        url = config.ROApi + `workspaces/${wsId}/sandbox/${project}/days/${serviceDay}/serviceLocations?allfields=false`;
        return axiosDefault.get(url, { headers });
    } else {
        return axiosDefault.get(url, { headers });
    }
};

export const getScenarioServiceLocations = (workspace, processId) => {
    const url = config.ROApi + `workspaces/${workspace}/raasjobs/${processId}/serviceLocations`;
    return axiosDefault.get(url, axiosHeaders());
};

export const pinServiceLocations = (wsId, selGids, streetLinkId, streetX, streetY) => {
    const url = config.ROApi + 'workspaces/' + wsId + '/servicelocations/pin?linkid=' + streetLinkId + '&x=' + streetX + '&y=' + streetY + '&pw=1';
    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
        'content-type': 'application/json; charset=utf-8',
    };
    return axios({
        method: 'POST',
        url,
        data: JSON.stringify(selGids),
        headers,
    });
};

export const unpinServiceLocations = (wsId, selGids) => {
    const url = `${config.ROApi}workspaces/${wsId}/servicelocations/unpin?pw=1`;

    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
        'content-type': 'application/json',
    };
    return axios({
        method: 'POST',
        url,
        data: JSON.stringify(selGids),
        headers,
    });
};

export const flipServiceLocations = (wsId, selGids) => {
    const url = `${config.ROApi}workspaces/${wsId}/servicelocations/flip?pw=1`;
    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
        'content-type': 'application/json',
    };
    return axios({
        method: 'POST',
        url,
        data: JSON.stringify(selGids),
        headers,
    });
};

export const getRouteSummaries = (workspaceId, serviceDay, siteMode, project) => {
    let userInfo = JSON.parse(window.sessionStorage['userInfo']);
    let accessToken = userInfo ? userInfo.accessToken : '';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    let url = '';
    if (siteMode === 'DISPATCHER') {
        url = config.ROApi + `workspaces/${workspaceId}/routeSummaries/${serviceDay}?isMDR=true`;
        return axiosDefault.get(url, { headers });
    } else if (siteMode === 'PLANNER') {
        url = config.ROApi + `workspaces/${workspaceId}/sandbox/${project}/expandedRouteSummary/${serviceDay}?isMDR=true`;
        return axiosDefault.get(url, { headers });
    }
};

export const getWorkspaceServiceDayHealthScore = (workspaceId, serviceDay, siteMode, project) => {
    let userInfo = JSON.parse(window.sessionStorage['userInfo']);
    let url = `${config.ROApi}workspace/${workspaceId}/${serviceDay}/healthscore`;
    if (siteMode === 'PLANNER') {
        url = `${config.ROApi}workspaces/${workspaceId}/sandboxes/${project}/healthscores/${serviceDay}`;
    }
    let accessToken = userInfo ? userInfo.accessToken : '';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.get(url, { headers });
};

export const getReportTemplates = (wsId) => {
    let url = config.ROApi + 'workspaces/' + wsId + '/batchreports';
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.get(url, { headers });
};

export const getReports = (workspaceId, serviceDay, routes, reports, scenarioJobId, siteMode, project) => {
    let url = config.ROApi + 'reports/Reports';
    let data = {
        WorkspaceID: workspaceId,
        Routes: routes,
        Reports: reports,
    };
    if (scenarioJobId) {
        data.ProcessID = scenarioJobId;
    } else {
        data.ServiceDay = serviceDay;
    }
    if (siteMode === 'PLANNER') {
        url += '?sandboxId=' + project;
    }
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
        Accept: 'application/pdf',
    };
    return axiosDefault.post(url, data, { headers, responseType: 'arraybuffer' });
};

export const updateStreetClosure = (workspaceId, linkId) => {
    const url = config.ROApi + 'workspaces/' + workspaceId + '/StreetClosed';

    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
        'content-type': 'application/json',
    };

    return axios({
        method: 'PUT',
        url,
        data: JSON.stringify(linkId),
        headers,
    });
};

export const updateServiceSide = (workspaceId, linkId, meander) => {
    const url = config.ROApi + 'workspaces/' + workspaceId + '/ServiceMode';

    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
        'content-type': 'application/x-www-form-urlencoded',
    };

    return axios({
        method: 'PUT',
        url,
        data: 'WorkspaceId=' + workspaceId + '&LinkID=' + linkId + '&Meander=' + meander,
        headers,
    });
};

export const putTurnException = (wsId, linkId1, linkId2) => {
    const url = config.ROApi + 'workspaces/' + wsId + '/TurnExceptions';
    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
        'content-type': 'application/json',
    };
    return axios({
        method: 'PUT',
        url,
        data: linkId1 + ',' + linkId2,
        headers,
    });
};

export const putUTurnException = (wsId, linkId1, linkId2, x, y) => {
    const url = config.ROApi + 'workspaces/' + wsId + '/TurnExceptions';
    let postData = linkId1 + ',' + linkId2 + ',' + x + ',' + y;
    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
        'content-type': 'application/json',
    };
    return axios({
        method: 'PUT',
        url,
        data: postData,
        headers,
    });
};

export const getAlleyAssignments = (workspace) => {
    let url = `${config.ROApi}workspaces/${workspace}/alleyassignments`;
    return axiosDefault.get(url, axiosHeaders());
};

export const createAlleyAssignments = (data) => {
    let url = `${config.ROApi}workspaces/${data.WorkspaceId}/alleyassignments`;
    return axiosDefault.post(url, data, axiosHeaders());
};

export const deleteAlleyAssignments = (workspace, id) => {
    let url = `${config.ROApi}workspaces/${workspace}/alleyassignments/${id}`;
    return axiosDefault.delete(url, axiosHeaders());
};

export const checkAlleyAssignments = (workspace) => {
    let url = `${config.ROApi}workspaces/${workspace}/alleyassignments/check`;
    return axiosDefault.put(url, null, axiosHeaders());
};

export const deleteUserTurnException = (workspaceId, gid) => {
    const url = config.ROApi + 'workspace/' + workspaceId + '/TurnExceptions/' + gid;
    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
    };
    return axiosDefault.delete(url, { headers });
};

export const getStreetNote = (workspaceId, linkId) => {
    let url = config.ROApi + `workspaces/${workspaceId}/StreetNotes/${linkId}`;
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.get(url, { headers });
};

export const postStreetNote = (target) => {
    const url = config.ROApi + 'workspaces/' + target.WorkspaceId + '/StreetNotes';
    let postData = {
        WorkspaceId: target.WorkspaceId,
        LinkID: target.LinkID,
        LNote: target.LNote,
        RNote: target.RNote,
    };
    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
    };
    return axiosDefault.post(url, postData, { headers });
};

export const putStreetNote = (target) => {
    const url = config.ROApi + 'workspaces/' + target.WorkspaceId + '/StreetNotes';
    let postData = {
        WorkspaceId: target.WorkspaceId,
        LinkID: target.LinkID,
        LNote: target.LNote,
        RNote: target.RNote,
    };
    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
    };
    return axiosDefault.put(url, postData, { headers });
};

export const deleteStreetNote = (workspaceId, streetNoteId) => {
    const url = config.ROApi + 'workspaces/' + workspaceId + '/StreetNotes/' + streetNoteId;

    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
    };

    return axiosDefault.delete(url, { headers });
};

export const getTravelPathData = (workspaceId, selServiceDay, route, siteMode, project) => {
    let url = config.ROApi + `workspaces/${workspaceId}/Routes/${route}/TravelPaths?fraction=0.3&serviceDay=${selServiceDay}`;
    if (siteMode === 'PLANNER') {
        url += `&sandboxId=${project}`;
    }
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.get(url, { headers });
};

export const getExportFormats = (workspaceId) => {
    let url = config.ROApi + 'workspaces/' + workspaceId + '/servicelocations/formats';
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.get(url, { headers });
};

export const exportServiceLocations = (obj) => {
    let userInfo = JSON.parse(window.sessionStorage['userInfo']);
    let accessToken = userInfo ? userInfo.accessToken : '';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
        Accept: obj.header,
    };
    if (obj.gids.length === 0) {
        let url = `${config.ROApi}workspaces/${obj.workspace}/servicelocations/${obj.url}`;
        if (obj.processId) {
            url += `/${obj.processId}`;
        }
        let data = { WorkspaceID: obj.workspace };
        if (obj.processId) {
            data['Routes'] = obj.routes;
        } else {
            if (obj.siteMode === 'DISPATCHER') {
                if (obj.serviceDay === 0) {
                    if (sessionStorage.getItem('mdr') === 'true') {
                        data['ServiceDay'] = 1234567;
                        data['Routes'] = obj.routes.replace('No Route', '0');
                    } else {
                        data['ServiceDay'] = Number(obj.routes.replace(/,/g, ''));
                        data['Routes'] = '*';
                    }
                } else {
                    data['ServiceDay'] = obj.serviceDay;
                    data['Routes'] = obj.routes.replace('No Route', '0');
                }
            } else if (obj.siteMode === 'PLANNER') {
                let sd = obj.serviceDay;
                if (obj.serviceDay === 0) {
                    if (sessionStorage.getItem('mdr') === 'true') {
                        sd = 1234567;
                        data = obj.routes.replace('No Route', '0');
                    } else {
                        sd = obj.routes.replace(/,/g, '');
                        data = '*';
                    }
                } else {
                    data = obj.routes.replace('No Route', '0');
                }
                url = `${config.ROApi}workspaces/${obj.workspace}/sandboxes/${obj.project}/servicelocations/${obj.url}?changed=false&serviceDay=${sd}`;
            }
        }
        return axiosDefault.post(url, data, { headers, responseType: 'arraybuffer' });
    } else {
        if (obj.processId) {
            let url = `${config.ROApi}workspaces/${obj.workspace}/servicelocations/${obj.url}/${obj.processId}/records`;
            return axiosDefault.post(url, obj.gids, { headers, responseType: 'arraybuffer' });
        } else {
            let url = `${config.ROApi}workspaces/${obj.workspace}/servicelocations/${obj.url}/gids`;
            if (obj.siteMode === 'PLANNER') {
                url = `${config.ROApi}workspaces/${obj.workspace}/sandboxes/${obj.project}/servicelocations/${obj.url}/gids`;
            }
            return axiosDefault.post(url, obj.gids, { headers, responseType: 'arraybuffer' });
        }
    }
};

export const exportRouteDayServiceLocations = (obj, data) => {
    let userInfo = JSON.parse(window.sessionStorage['userInfo']);
    let accessToken = userInfo ? userInfo.accessToken : '';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
        Accept: obj.header,
    };
    if (obj.gids.length === 0) {
        let url = `${config.ROApi}workspaces/${obj.workspace}/servicelocations/${obj.url}/routedays`;
        if (obj.siteMode === 'PLANNER') {
            url = `${config.ROApi}workspaces/${obj.workspace}/sandboxes/${obj.project}/servicelocations/${obj.url}/routedays`;
        }
        return axiosDefault.post(url, data, { headers, responseType: 'arraybuffer' });
    } else {
        let url = `${config.ROApi}workspaces/${obj.workspace}/servicelocations/${obj.url}/gids`;
        if (obj.siteMode === 'PLANNER') {
            url = `${config.ROApi}workspaces/${obj.workspace}/sandboxes/${obj.project}/servicelocations/${obj.url}/gids`;
        }
        return axiosDefault.post(url, obj.gids, { headers, responseType: 'arraybuffer' });
    }
};

export const getSeqGap = (keyword) => {
    let userInfo = JSON.parse(window.sessionStorage['userInfo']);
    let accessToken = userInfo ? userInfo.accessToken : '';
    const url = config.ROApi + 'raas/settings/' + keyword + '?client=' + userInfo.ClientID;
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.get(url, { headers });
};

export const getSequenceLayerData = (workspaceId, route, selServiceDay, siteMode, project) => {
    let url = config.ROApi + `workspaces/${workspaceId}/Routes/${route}/SequenceInfo?service_day=${selServiceDay}`;
    if (siteMode === 'PLANNER') {
        url += `&sandboxId=${project}`;
    }
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.get(url, { headers });
};

export const getDispatchHistory = (workspaceId, selServiceDay) => {
    let url = config.ROApi + `workspaces/${workspaceId}/dispatch/history`;
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.get(url, { headers });
};

export const getRouteSummary = (workspaceId, processId) => {
    let url = config.ROApi + `workspaces/${workspaceId}/raasjobs/${processId}/routeSummary?includeGeoCounts=false`;
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.get(url, { headers });
};

export const getJobInfo = (workspaceId, processId) => {
    let url = config.ROApi + `workspaces/${workspaceId}/raasjobs/${processId}/jobdata?&fields=extent,facilities,routes`;
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.get(url, { headers });
};

export const getJobRouteExtent = (workspaceId, processId, routes) => {
    let url = config.ROApi + `workspaces/${workspaceId}/raasjobs/${processId}/ServiceLocation/extent`;
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
        'content-type': 'application/json',
    };
    return axiosDefault.post(url, routes, { headers });
};

export const getJobRouteSequence = (workspaceId, processId, route) => {
    let url = config.ROApi + `workspaces/${workspaceId}/raasjobs/${processId}/maplayers/sequence?routes=${route}`;
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.get(url, { headers });
};

export const getJobRouteTravelPath = (workspaceId, processId, route, siteMode, project) => {
    let url = config.ROApi + `workspaces/${workspaceId}/raasjobs/${processId}/maplayers/travelpath?routes=${route}`;
    if (siteMode === 'PLANNER') {
        url += `&sandboxId=${project}`;
    }
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.get(url, { headers });
};

export const postDispatch = (command, target) => {
    let url = config.ROApi + `workspaces/${target.WorkspaceID}/Solver/${command}`;
    let postData;
    if (command === 'Split') {
        postData = {
            DesiredTime: target.DesiredTime,
            DesLoad: target.DesLoad,
            EndOfficeID: target.EndOfficeID,
            Frequencies: target.Frequencies,
            NewRouteLabel: target.NewRouteLabel,
            NumberOfRoutes: target.NumberOfRoutes,
            OfficeID: target.OfficeID,
            Publications: target.Publications,
            Reports: target.Reports,
            Routes: target.Routes,
            SequenceNum: target.SequenceNum,
            ServiceDay: target.ServiceDay,
            StartTime: target.StartTime,
            WorkspaceID: target.WorkspaceID,
        };
    } else if (command === 'Create') {
        postData = {
            WorkspaceID: target.WorkspaceID,
            EndOfficeID: -3,
            ServiceDay: target.ServiceDay,
            RoutePlanId: target.RoutePlan,
            GenerateMethod: target.GenerateMethod,
            Zones: 'Off',
        };
        if (target.Zones === 'Routes') {
            postData.Zones = 'Routes';
        }
        if (target.GenerateMethod === 'Number') {
            postData.NumberOfRoutes = target.NumRoutes;
        }
    }
    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
    };
    return axiosDefault.post(url, postData, { headers });
};

export const getWorkspaceDownload = (workspaceId, serviceDay) => {
    let url = config.ROApi + `workspaces/${workspaceId}/Solver/WorkspaceFile?serviceDay=${serviceDay}`;
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
        Accept: 'application/zip',
    };
    return axiosDefault.get(url, { headers, contentType: 'application/json', responseType: 'arraybuffer' });
};

export const getRouteSetServiceDay = (workspaceId, route, serviceDay) => {
    let url = config.ROApi + `workspaces/${workspaceId}/routesets/${route}/service/${serviceDay}`;
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.get(url, { headers });
};

export const getNeighborhoodClusters = (workspaceId, siteMode, project) => {
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';

    let url = config.ROApi + 'workspaces/' + workspaceId + '/anchorareas';
    if (siteMode === 'PLANNER') {
        url = config.ROApi + 'workspaces/' + workspaceId + '/sandboxes/' + project + '/anchorareas';
    }
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.get(url, { headers });
};

export const createNeighborhoodClusters = (workspaceId, data) => {
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';

    const url = config.ROApi + 'workspaces/' + workspaceId + '/anchorareas';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.post(url, data, { headers });
};

export const updateNeighborhoodClusters = (workspaceId, id, data) => {
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';

    const url = config.ROApi + 'workspaces/' + workspaceId + '/anchorareas/' + id;
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.put(url, data, { headers });
};

export const deleteNeighborhoodClusters = (workspaceId, id) => {
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    const url = config.ROApi + 'workspaces/' + workspaceId + '/anchorareas/' + id;
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.delete(url, { headers });
};

export const deleteSelectedZones = (workspace, data) => {
    const url = `${config.ROApi}workspaces/${workspace}/anchorareas`;
    return axiosDefault.delete(url, { data: data }, axiosHeaders());
};

export const getSequenceClusters = (workspaceId, routePlanId) => {
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';

    const url = config.ROApi + 'workspaces/' + workspaceId + '/sequenceclusters/routeplans/' + routePlanId;
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.get(url, { headers });
};

export const updateNeighborhoodClustersAssignment = (workspaceId, routePlanId, route, data) => {
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    const url = config.ROApi + 'workspaces/' + workspaceId + '/sequenceclusters/routeplans/' + routePlanId + '/routes/' + route;
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.put(url, data, { headers });
};

export const getServiceWindow = (workspaceId, data) => {
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    const url = config.ROApi + 'workspaces/' + workspaceId + '/servicewindows';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/json',
        Accept: '*/*',
    };
    return axiosDefault.get(url, { headers, data: {} });
    //return axiosDefault.get(url, {headers});
};

export const updateServiceWindow = (wsId, data) => {
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    const url = config.ROApi + 'workspaces/' + wsId + '/servicewindows';
    if (data) data.ID = 0;
    let headers = {
        Authorization: 'Bearer ' + accessToken,
        'content-type': 'application/json',
    };
    return axiosDefault.post(url, data, { headers });
};

export const getZoneInfo = (workspaceId, anchorAreaId, serviceDay) => {
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    const url = config.ROApi + 'workspaces/' + workspaceId + '/anchorareas/' + anchorAreaId + '/servicedays/' + serviceDay;
    let headers = {
        Authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/json',
        Accept: '*/*',
    };
    return axiosDefault.get(url, { headers });
};

export const getProjectRouteList = (workspaceId, serviceDay) => {
    let userInfo = JSON.parse(window.sessionStorage['userInfo']);
    let accessToken = userInfo ? userInfo.accessToken : '';
    let url = `${config.ROApi}workspaces/${workspaceId}/routes?funcId=209`;
    if (serviceDay > 0) {
        url += `&service_day=${serviceDay}`;
    }
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.get(url, { headers });
};

export const getProjects = (wsId, mode) => {
    let userInfo = JSON.parse(window.sessionStorage['userInfo']);
    let accessToken = userInfo ? userInfo.accessToken : '';
    let url = config.ROApi + 'workspaces/' + wsId + '/sandboxes';
    if (mode === true) {
        url += '?editable=true';
    } else if (mode === 'A') {
        url += '?all=true';
    } else if (mode === 'E') {
        url += '?editable=true';
    } else {
        url += '?only_mine=true';
    }
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.get(url, { headers });
};

export const createProject = (wsId, data) => {
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    const url = config.ROApi + 'workspaces/' + wsId + '/sandbox';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
        'content-type': 'application/json',
    };
    return axiosDefault.post(url, data, { headers });
};

export const updateProject = (wsId, sandboxId, data) => {
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    const url = config.ROApi + 'workspaces/' + wsId + '/sandbox/' + sandboxId;
    let headers = {
        Authorization: 'Bearer ' + accessToken,
        'content-type': 'application/json',
    };
    return axiosDefault.put(url, data, { headers });
};

export const deleteProject = (wsId, sandboxId) => {
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    const url = config.ROApi + 'workspaces/' + wsId + '/sandbox/' + sandboxId;
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.delete(url, { headers });
};

export const getScenarios = (wsId, sandboxId) => {
    let userInfo = JSON.parse(window.sessionStorage['userInfo']);
    let accessToken = userInfo ? userInfo.accessToken : '';
    let url = config.ROApi + 'workspaces/' + wsId + '/sandbox/' + sandboxId + '/scenarios';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.get(url, { headers });
};

export const createScenario = (wsId, sandboxId, data) => {
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    const url = config.ROApi + 'workspaces/' + wsId + '/sandbox/' + sandboxId + '/scenario';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
        'content-type': 'application/json',
    };
    return axiosDefault.put(url, data, { headers });
};

export const updateScenario = (wsId, sandboxId, scenarioId, data) => {
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    const url = config.ROApi + 'workspaces/' + wsId + '/sandbox/' + sandboxId + '/scenario/' + scenarioId;
    let headers = {
        Authorization: 'Bearer ' + accessToken,
        'content-type': 'application/json',
    };
    return axiosDefault.post(url, data, { headers });
};

export const deleteScenario = (wsId, sandboxId, scenarioId) => {
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    const url = config.ROApi + 'workspaces/' + wsId + '/sandbox/' + sandboxId + '/scenario/' + scenarioId;
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.delete(url, { headers });
};

export const loadScenario = (wsId, sandboxId, scenarioId) => {
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    const url = config.ROApi + 'workspaces/' + wsId + '/sandbox/' + sandboxId + '/scenario/' + scenarioId;
    let headers = {
        Authorization: 'Bearer ' + accessToken,
        'content-type': 'application/json',
    };
    return axiosDefault.put(url, null, { headers });
};

export const getRoutesFromProject = (wsId, sandboxId, serviceDay) => {
    let userInfo = JSON.parse(window.sessionStorage['userInfo']);
    let accessToken = userInfo ? userInfo.accessToken : '';
    let url = `${config.ROApi}workspaces/${wsId}/sandbox/${sandboxId}/days/${serviceDay}/routes`;
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.get(url, { headers });
};

export const swapStops = (wsId, sandboxId, data) => {
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    const url = config.ROApi + 'workspaces/' + wsId + '/sandbox/' + sandboxId + '/Servicelocations/Swap';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
        'content-type': 'application/json',
    };
    return axiosDefault.post(url, data, { headers });
};

export const swapPatterns = (workspace, project, data) => {
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    const url = `${config.ROApi}workspaces/${workspace}/sandboxes/${project}/servicepatterns/swap`;
    let headers = {
        Authorization: 'Bearer ' + accessToken,
        'content-type': 'application/json',
    };
    return axiosDefault.put(url, data, { headers });
};

export const callSandboxSolver = (wsId, project, solverType, data) => {
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    let url = `${config.ROApi}workspaces/${wsId}/sandbox/${project}/${solverType}`;
    let SD = data.serviceDay;
    if (data.serviceDay === 0) {
        SD = 'All Days';
    }
    if (solverType === 'Insert') {
        url += `?routePlanId=${data.routePlanId}&serviceDay=${SD}`;
    } else if (solverType === 'Create') {
        if (data.serviceDay === 0) {
            url += `?serviceDay=${SD}`;
        } else {
            if (data.routePlanId === 0) {
                url += `?routePlanId=${data.routePlanId}&serviceDay=${SD}`;
            } else {
                url += `?routePlanId=${data.routePlanId}&serviceDay=${SD}&zones=${data.Zones}&generateMethod=${data.GenerateMethod}`;
            }
            if (data.GenerateMethod === 'Number') {
                url += `&num_routes=${data.number}`;
            }
        }
        url += `&reuseExistPart=${data.anchorWithRoute ? 1 : 0}`;
    } else if (solverType === 'Sequence') {
        if (data.serviceDay === 0) {
            url += `?serviceDay=${SD}`;
        } else {
            url += `?routePlanId=${data.routePlanId}&serviceDay=${SD}`;
            if (data.Zones) {
                url += `&zones=${data.Zones}`;
            }
        }
    }
    let headers = {
        Authorization: 'Bearer ' + accessToken,
        'content-type': 'application/json',
    };
    return axiosDefault.post(url, '*', { headers });
};

export const createServiceDay = (workspace, project, data) => {
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    let url = `${config.ROApi}workspaces/${workspace}/sandbox/${project}/CreateServiceDays?`;
    url += `num_routes=${data.numberOfDays}&balanceOn=${data.balanceOn}&reuseExistPart=${data.anchorWithServiceDay ? 1 : 0}&vehicleCapacity=${
        data.truckCapacity
    }`;
    let headers = {
        Authorization: 'Bearer ' + accessToken,
        'content-type': 'application/json',
    };
    return axiosDefault.post(url, '*', { headers });
};

export const createTerritories = (workspace, project, data) => {
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    let url = `${config.ROApi}workspaces/${workspace}/sandbox/${project}/CreateRouteTerritories`;
    url += `?routePlanId=${data.routePlan}&generateMethod=${data.method}`;
    if (data.method === 'Number') {
        url += `&num_routes=${data.number}&balanceOn=${data.balanceOn}`;
    } else if (data.method === 'Full') {
        url += `&balanceOn=${data.balanceOn}`;
    }
    url += `&zones=${data.zones}&reuseExistPart=${data.anchorWithRoute ? 1 : 0}`;
    let headers = {
        Authorization: 'Bearer ' + accessToken,
        'content-type': 'application/json',
    };
    return axiosDefault.post(url, '*', { headers });
};

export const balanceServiceDays = (workspace, project, data) => {
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    let url = `${config.ROApi}workspaces/${workspace}/sandbox/${project}/BalanceServiceDays`;
    url += `?insDesiredLength=${data.dailyRouteTime}`;
    url += `&mdServDayMult1=${data.monLoad}`;
    url += `&mdServDayMult2=${data.tuesLoad}`;
    url += `&mdServDayMult3=${data.wedLoad}`;
    url += `&mdServDayMult4=${data.thurLoad}`;
    url += `&mdServDayMult5=${data.friLoad}`;
    url += `&mdServDayMult6=${data.satLoad ? data.satLoad : '0.00'}`;
    let headers = {
        Authorization: 'Bearer ' + accessToken,
        'content-type': 'application/json',
    };
    return axiosDefault.post(url, '*', { headers });
};

export const getGeocodedFacilities = (wsId, sandboxId) => {
    let userInfo = JSON.parse(window.sessionStorage['userInfo']);
    let accessToken = userInfo ? userInfo.accessToken : '';
    let url = config.ROApi + 'workspaces/' + wsId + '/sandbox/' + sandboxId + '/facilities?geocodedOnly=true';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.get(url, { headers });
};

export const sendToNavusoft = (workspaceId, siteMode, project) => {
    let url = config.ROApi + `workspaces/${workspaceId}/servicelocations/navusoft`;
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    if (siteMode === 'PLANNER') {
        url = config.ROApi + `workspaces/${workspaceId}/sandbox/${project}/servicelocations/navusoft`;
    }
    return axiosDefault.post(url, null, { headers });
};

export const getTrips = (workspaceId, siteMode, project) => {
    let userInfo = JSON.parse(window.sessionStorage['userInfo']);
    let accessToken = userInfo ? userInfo.accessToken : '';
    let url = config.ROApi + 'workspaces/' + workspaceId + '/servicelocations/trips';
    if (siteMode === 'PLANNER') {
        url = config.ROApi + 'workspaces/' + workspaceId + '/sandboxes/' + project + '/trips';
    }
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.get(url, { headers });
};

export const getServicePatterns = (workspace, project) => {
    let userInfo = JSON.parse(window.sessionStorage['userInfo']);
    let accessToken = userInfo ? userInfo.accessToken : '';
    let url = config.ROApi + 'workspaces/' + workspace + '/sandboxes/' + project + '/servicepatterns';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.get(url, { headers });
};

export const createServicePattern = (workspace, project, data) => {
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    let url = config.ROApi + 'workspaces/' + workspace + '/sandboxes/' + project + '/servicepatterns';
    return axiosDefault.post(url, data, { headers });
};

export const updateServicePattern = (workspace, project, id, data) => {
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    let url = config.ROApi + 'workspaces/' + workspace + '/sandboxes/' + project + '/servicepatterns/' + id;
    return axiosDefault.put(url, data, { headers });
};

export const deleteServicePattern = (workspace, project, id) => {
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    let url = config.ROApi + 'workspaces/' + workspace + '/sandboxes/' + project + '/servicepatterns/' + id;
    return axiosDefault.delete(url, { headers });
};

export const exportServicePattern = (workspace, serviceDay, project, routes) => {
    let sd = serviceDay;
    let data = '*';
    if (serviceDay === 0) {
        if (sessionStorage.getItem('mdr') === 'true') {
            sd = 1234567;
            data = routes.replace('No Route', '0');
        } else {
            sd = routes.replace(/,/g, '');
            data = '*';
        }
    } else {
        data = routes.replace('No Route', '0');
    }
    let url = `${config.ROApi}workspaces/${workspace}/sandboxes/${project}/servicepatternchanges/csv?serviceDay=${sd}`;
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
        Accept: 'text/csv; charset=utf-8',
        'content-type': 'application/json',
    };
    return axiosDefault.post(url, data, { headers, responseType: 'arraybuffer' });
};

export const exportRouteDayServicePatterns = (workspace, project, data) => {
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    let url = `${config.ROApi}workspaces/${workspace}/sandboxes/${project}/servicepatterns/csv`;
    let headers = {
        Authorization: 'Bearer ' + accessToken,
        Accept: 'text/csv; charset=utf-8',
        'content-type': 'application/json',
    };
    return axiosDefault.post(url, data, { headers, responseType: 'arraybuffer' });
};

export const getRouteDayExtent = (workspace, data, siteMode, project) => {
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    let url = `${config.ROApi}workspaces/${workspace}/servicelocations/routedaysextent?buffer=0`;
    if (siteMode === 'PLANNER') {
        url += `&sandboxId=${project}`;
    }
    const headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.post(url, data, { headers });
};

export const getRouteDay = (wsId, siteMode, project) => {
    let url = config.ROApi + `workspaces/${wsId}/routedays`;
    if (siteMode === 'PLANNER') {
        url = config.ROApi + `workspaces/${wsId}/sandbox/${project}/routedays`;
    }
    return axiosDefault.get(url, axiosHeaders());
};

export const getServiceAreaBoundary = (workspace) => {
    let url = `${config.ROApi}workspaces/${workspace}/serviceareas`;
    return axiosDefault.get(url, axiosHeaders());
};

export const createServiceAreaBoundary = (workspace, data) => {
    let url = `${config.ROApi}workspaces/${workspace}/serviceareas`;
    return axiosDefault.post(url, data, axiosHeaders());
};

export const updateServiceAreaBoundary = (workspace, data) => {
    let url = `${config.ROApi}workspaces/${workspace}/serviceareas`;
    return axiosDefault.put(url, data, axiosHeaders());
};

export const deleteServiceAreaBoundary = (workspace) => {
    let url = `${config.ROApi}workspaces/${workspace}/serviceareas`;
    return axiosDefault.delete(url, axiosHeaders());
};

export const sendSmartSuite = (data) => {
    let url = `${config.ROApi}workspaces/${data.workspace}/smartsuite?serviceDay=${data.serviceDay}`;
    if (data.JobId) {
        url = `${config.ROApi}workspaces/${data.workspace}/raasjobs/${data.JobId}/sendtosmartsuite`;
    } else if (data.project) {
        url = `${config.ROApi}workspaces/${data.workspace}/sandboxes/${data.project}/smartsuite?serviceDay=${data.serviceDay}`;
    }
    return axiosDefault.post(url, null, axiosHeaders());
};

export const submitJob = (data) => {
    let url = `${config.ROApi}submissions`;
    return axiosDefault.post(url, data, axiosHeaders());
};

export const getSequence = (workspace, serviceDay, project, route) => {
    let url = `${config.ROApi}workspaces/${workspace}/sandboxes/${project}/routes/${route}/days/${serviceDay}/svclocsequences`;
    return axiosDefault.get(url, axiosHeaders());
};

export const updateSequence = (workspace, serviceDay, project, route, data) => {
    let url = `${config.ROApi}workspaces/${workspace}/sandboxes/${project}/routes/${route}/days/${serviceDay}/svclocsequences`;
    return axiosDefault.patch(url, data, axiosHeaders());
};

export const getSequenceSolution = (workspace, serviceDay, project, route) => {
    let url = `${config.ROApi}workspaces/${workspace}/sandboxes/${project}/routes/${route}/days/${serviceDay}/sequences`;
    return axiosDefault.get(url, axiosHeaders());
};

export const submitSandboxSequence = (workspace, serviceDay, project, route) => {
    let url = `${config.ROApi}workspaces/${workspace}/sandboxes/${project}/routes/${route}/days/${serviceDay}/sequences`;
    return axiosDefault.post(url, null, axiosHeaders());
};

export const getGapSetting = (workspace) => {
    const url = `${config.ROApi}workspaces/${workspace}/SeqGap`;
    return axiosDefault.get(url, axiosHeaders());
};

export const refreshServiceLocations = (workspace, project, data) => {
    let url = `${config.ROApi}workspaces/${workspace}/sandboxes/${project}/refreshsldata`;
    return axiosDefault.post(url, data, axiosHeaders());
};

export const deleteServiceLocations = (workspace, data, siteMode, project) => {
    let url = `${config.ROApi}workspaces/${workspace}/servicelocations`;
    if (siteMode === 'PLANNER') {
        url = `${config.ROApi}workspaces/${workspace}/sandboxes/${project}/servicelocations`;
    }
    return axiosDefault.delete(url, { data: data }, axiosHeaders());
};
