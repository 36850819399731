import React, { useState, useEffect } from 'react';
import { Grid, IconButton } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import { postDriver, getDrivers, putDriver, deleteDriver, getSpecialties } from '../../_actions/Manage.actions';
import { useWindowSize } from './ManageController';
import { updateUserActivity } from '../../_actions/Global.actions';
import { EditDialogue } from './EditDialogue';
import { DeleteDialogue } from './DeleteDialogue';
import { useTranslation } from 'react-i18next';
import { ManagementSpecialtyDialog } from './SpecialtyDialogs';

const DriverTable = (props) => {
    const { t } = useTranslation();

    const title = t('RoutePlans.Driver.Title');
    const defaultName = 'Default';

    const size = useWindowSize();
    let driverContainerHeight = (size.height - 195) / 2;
    let driverGridHeight = driverContainerHeight - 48;

    const driverCustomSort = (a, b, c) => {
        if (b && b.original && b.original['DriverName'] === defaultName) {
            return 1;
        } else if (a && a.original && a.original['DriverName'] === defaultName) {
            return -1;
        } else {
            if (isNaN(a.original[c]) || isNaN(b.original[c])) {
                return a.original[c].localeCompare(b.original[c], 'en', { numeric: true });
            } else {
                return a.original[c] - b.original[c];
            }
        }
    };

    const [data, setData] = React.useState([]);
    const [editDriverDialog, setEditDriverDialog] = React.useState(false);
    const [deleteDriverDialog, setDeleteDriverDialog] = React.useState(false);
    const [mode, setMode] = React.useState('New');
    const [target, setTarget] = React.useState({});
    const [editingName, setEditingName] = React.useState('');
    const [payMethod, setPayMethod] = React.useState('1');
    const [driverSpecialties, setDriverSpecialties] = React.useState([]);

    const [manageSpecialtyDialogOpen, setManageSpecialtyDialogOpen] = React.useState(false);
    const [specialtyList, setSpecialtyList] = useState([]);

    const { workspace } = props;

    const renderDriverGrid = () => {
        let columns = [
            {
                header: t('RoutePlans.Driver.Columns.Driver'),
                Header: ({ column }) => (
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <span
                            className='DR_DRIVE'
                            style={{ fontSize: '22px' }}
                        ></span>
                        {column.columnDef.header}
                    </div>
                ),
                accessorKey: 'DriverName',
                size: 50,
                sortingFn: (a, b, c) => driverCustomSort(a, b, c),
                muiTableHeadCellProps: { align: 'center', sx: { fontSize: '12px' } },
                muiTableBodyCellProps: { sx: { fontSize: '12px', borderBottom: 'none' } },
                Cell: ({ cell, row }) => {
                    if (cell.getValue() === defaultName) {
                        return (
                            <b>
                                <i>{cell.getValue()}</i>
                            </b>
                        );
                    } else {
                        return cell.getValue();
                    }
                },
            },
            {
                header: t('RoutePlans.Driver.Columns.Hourly'),
                Header: ({ column }) => (
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <span
                            className='DR_HOUR'
                            style={{ fontSize: '22px' }}
                        ></span>
                        {column.columnDef.header}
                    </div>
                ),
                accessorKey: 'CostPerHour',
                size: 50,
                sortingFn: (a, b, c) => driverCustomSort(a, b, c),
                muiTableHeadCellProps: { align: 'center', sx: { fontSize: '12px' } },
                muiTableBodyCellProps: ({ cell, table }) => ({
                    align: 'center',
                    sx:
                        cell.getValue() === 0
                            ? { backgroundColor: '#EEE', borderBottom: 'none', padding: '0 0.5rem' }
                            : { fontSize: '12px', borderBottom: 'none', padding: '0 0.5rem' },
                }),
                Cell: ({ cell, row }) => {
                    if (row.original.DriverName === defaultName) {
                        if (cell.getValue() === 0) {
                            return (
                                <b>
                                    <i>-</i>
                                </b>
                            );
                        } else {
                            return (
                                <b>
                                    <i>${cell.getValue().toFixed(2)}</i>
                                </b>
                            );
                        }
                    } else {
                        if (cell.getValue() === 0) {
                            return `-`;
                        } else {
                            return `$${cell.getValue().toFixed(2)}`;
                        }
                    }
                },
            },
            {
                header: t('RoutePlans.Driver.Columns.Overtime'),
                Header: ({ column }) => (
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <span
                            className='DR_OT_PAY_HOUR'
                            style={{ fontSize: '22px' }}
                        ></span>
                        {column.columnDef.header}
                    </div>
                ),
                accessorKey: 'CostPerOtHour',
                size: 50,
                sortingFn: (a, b, c) => driverCustomSort(a, b, c),
                muiTableHeadCellProps: { align: 'center', sx: { fontSize: '12px' } },
                muiTableBodyCellProps: ({ cell, table }) => ({
                    align: 'center',
                    sx:
                        cell.getValue() === 0
                            ? { backgroundColor: '#EEE', borderBottom: 'none', padding: '0 0.5rem' }
                            : { fontSize: '12px', borderBottom: 'none', padding: '0 0.5rem' },
                }),
                Cell: ({ cell, row }) => {
                    if (row.original.DriverName === defaultName) {
                        if (cell.getValue() === 0) {
                            return (
                                <b>
                                    <i>-</i>
                                </b>
                            );
                        } else {
                            return (
                                <b>
                                    <i>${cell.getValue().toFixed(2)}</i>
                                </b>
                            );
                        }
                    } else {
                        if (cell.getValue() === 0) {
                            return `-`;
                        } else {
                            return `$${cell.getValue().toFixed(2)}`;
                        }
                    }
                },
            },
            {
                header: t('RoutePlans.Driver.Columns.Stop'),
                Header: ({ column }) => (
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <span
                            className='DR_STOP'
                            style={{ fontSize: '22px' }}
                        ></span>
                        {column.columnDef.header}
                    </div>
                ),
                accessorKey: 'CostPerStop',
                size: 50,
                sortingFn: (a, b, c) => driverCustomSort(a, b, c),
                muiTableHeadCellProps: { align: 'center', sx: { fontSize: '12px' } },
                muiTableBodyCellProps: ({ cell, table }) => ({
                    align: 'center',
                    sx:
                        cell.getValue() === 0
                            ? { backgroundColor: '#EEE', borderBottom: 'none', padding: '0 0.5rem' }
                            : { fontSize: '12px', borderBottom: 'none', padding: '0 0.5rem' },
                }),
                Cell: ({ cell, row }) => {
                    if (row.original.DriverName === defaultName) {
                        if (cell.getValue() === 0) {
                            return (
                                <b>
                                    <i>-</i>
                                </b>
                            );
                        } else {
                            return (
                                <b>
                                    <i>${cell.getValue().toFixed(2)}</i>
                                </b>
                            );
                        }
                    } else {
                        if (cell.getValue() === 0) {
                            return `-`;
                        } else {
                            return `$${cell.getValue().toFixed(2)}`;
                        }
                    }
                },
            },
            {
                header: t('RoutePlans.Driver.Columns.Demand'),
                Header: ({ column }) => (
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <span
                            className='DR_DEMAND'
                            style={{ fontSize: '22px' }}
                        ></span>
                        {column.columnDef.header}
                    </div>
                ),
                accessorKey: 'CostPerPiece',
                size: 50,
                sortingFn: (a, b, c) => driverCustomSort(a, b, c),
                muiTableHeadCellProps: { align: 'center', sx: { fontSize: '12px' } },
                muiTableBodyCellProps: ({ cell, table }) => ({
                    align: 'center',
                    sx:
                        cell.getValue() === 0
                            ? { backgroundColor: '#EEE', borderBottom: 'none', padding: '0 0.5rem' }
                            : { fontSize: '12px', borderBottom: 'none', padding: '0 0.5rem' },
                }),
                Cell: ({ cell, row }) => {
                    if (row.original.DriverName === defaultName) {
                        if (cell.getValue() === 0) {
                            return (
                                <b>
                                    <i>-</i>
                                </b>
                            );
                        } else {
                            return (
                                <b>
                                    <i>${cell.getValue().toFixed(2)}</i>
                                </b>
                            );
                        }
                    } else {
                        if (cell.getValue() === 0) {
                            return `-`;
                        } else {
                            return `$${cell.getValue().toFixed(2)}`;
                        }
                    }
                },
            },
            {
                header: t('RoutePlans.Driver.Columns.Distance'),
                Header: ({ column }) => (
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <span
                            className='DR_DISTANCE'
                            style={{ fontSize: '22px' }}
                        ></span>
                        {column.columnDef.header}
                    </div>
                ),
                accessorKey: 'CostPerDist',
                size: 50,
                sortingFn: (a, b, c) => driverCustomSort(a, b, c),
                muiTableHeadCellProps: { align: 'center', sx: { fontSize: '12px' } },
                muiTableBodyCellProps: ({ cell, table }) => ({
                    align: 'center',
                    sx:
                        cell.getValue() === 0
                            ? { backgroundColor: '#EEE', borderBottom: 'none', padding: '0 0.5rem' }
                            : { fontSize: '12px', borderBottom: 'none', padding: '0 0.5rem' },
                }),
                Cell: ({ cell, row }) => {
                    if (row.original.DriverName === defaultName) {
                        if (cell.getValue() === 0) {
                            return (
                                <b>
                                    <i>-</i>
                                </b>
                            );
                        } else {
                            return (
                                <b>
                                    <i>${cell.getValue().toFixed(2)}</i>
                                </b>
                            );
                        }
                    } else {
                        if (cell.getValue() === 0) {
                            return `-`;
                        } else {
                            return `$${cell.getValue().toFixed(2)}`;
                        }
                    }
                },
            },
        ];
        if (props.siteMode !== 'PLANNER') {
            columns.push({
                header: '',
                accessorKey: 'actions',
                enableSorting: false,
                muiTableBodyCellProps: { align: 'center', sx: { borderBottom: 'none', padding: '0' } },
                size: 50,
                Cell: ({ cell, row }) => {
                    return row.original.DriverName === defaultName ? (
                        <div style={{ whiteSpace: 'nowrap' }}>
                            <IconButton
                                title={t('RoutePlans.Driver.Actions.Edit')}
                                color='primary'
                                className={'icon_button'}
                                style={{ color: '#16a8a6', marginLeft: '5px', padding: '0', fontSize: '18px' }}
                                onClick={() => handleEditDriver(row.original)}
                            >
                                <i className='RS_EDIT' />
                            </IconButton>
                        </div>
                    ) : (
                        <div style={{ whiteSpace: 'nowrap' }}>
                            <IconButton
                                title={t('RoutePlans.Driver.Actions.Copy')}
                                color='primary'
                                className={'icon_button'}
                                style={{ color: '#16a8a6', marginLeft: '5px', padding: '0', fontSize: '18px' }}
                                onClick={() => handleCopyDriver(row.original)}
                            >
                                <i className='RS_COPY' />
                            </IconButton>
                            <IconButton
                                title={t('RoutePlans.Driver.Actions.Edit')}
                                color='primary'
                                className={'icon_button'}
                                style={{ color: '#16a8a6', marginLeft: '5px', padding: '0', fontSize: '18px' }}
                                onClick={() => handleEditDriver(row.original)}
                            >
                                <i className='RS_EDIT' />
                            </IconButton>
                            <IconButton
                                title={t('RoutePlans.Driver.Actions.Delete')}
                                color='primary'
                                className={'icon_button'}
                                style={{ color: '#16a8a6', marginLeft: '5px', padding: '0', fontSize: '18px' }}
                                onClick={() => handleDeleteDriver(row.original)}
                            >
                                <i className='RS_DELETE' />
                            </IconButton>
                        </div>
                    );
                },
            });
        }

        let hasStop = false,
            hasDemand = false,
            hasDistance = false;
        data.map((d) => {
            if (d.CostPerStop) {
                hasStop = true;
            } else if (d.CostPerPiece) {
                hasDemand = true;
            } else if (d.CostPerDist) {
                hasDistance = true;
            }
        });

        const columnVisibility = {
            CostPerStop: hasStop,
            CostPerPiece: hasDemand,
            CostPerDist: hasDistance,
        };

        return (
            <MaterialReactTable
                columns={columns}
                data={data}
                enableTopToolbar={false}
                enableStickyHeader={true}
                enableColumnFilters={false}
                enablePagination={false}
                enableBottomToolbar={false}
                enableColumnActions={false}
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableHiding={false}
                initialState={{
                    columnVisibility: columnVisibility,
                    density: 'compact',
                    showGlobalFilter: false,
                    sorting: [{ id: 'DriverName', desc: false }],
                }}
                muiTableContainerProps={{
                    sx: { maxHeight: isNaN(driverGridHeight) ? '340px' : driverGridHeight + 'px' },
                }}
                state={{ columnVisibility: columnVisibility }}
            />
        );
    };

    const handleNewDriver = () => {
        const record = data.find((d) => d.DriverName === defaultName);
        if (record) {
            setMode('New');
            setTarget(record);
            setEditDriverDialog(true);
        }
    };

    const handleCopyDriver = (data) => {
        setMode('Copy');
        setTarget(data);
        if (data.CostPerStop) {
            setPayMethod('2');
        } else if (data.CostPerPiece) {
            setPayMethod('3');
        } else if (data.CostPerDist) {
            setPayMethod('4');
        }
        setEditingName(data.DriverName);
        setEditDriverDialog(true);
    };

    const handleEditDriver = (data) => {
        setMode('Edit');
        setTarget(data);
        if (data.SkillSetProperties) {
            let s = [];
            data.SkillSetProperties.forEach((ss, i) => {
                let ds = specialtyList.find((d) => d.value === ss);
                if (ds) {
                    s.push({ ...ds, Id: i });
                }
            });
            setDriverSpecialties(s);
        } else {
            setDriverSpecialties([]);
        }
        if (data.CostPerStop) {
            setPayMethod('2');
        } else if (data.CostPerPiece) {
            setPayMethod('3');
        } else if (data.CostPerDist) {
            setPayMethod('4');
        }
        setEditingName(data.DriverName);
        setEditDriverDialog(true);
    };

    const handleDeleteDriver = (data) => {
        setMode('Delete');
        setTarget(data);
        setDeleteDriverDialog(true);
    };

    const handleEditClose = async (option) => {
        if (option === 'CANCEL') {
            setEditDriverDialog(false);
            setTarget({});
            setDriverSpecialties([]);
            setPayMethod('1');
        } else if (option === 'SAVE') {
            let data = { ...target };
            if (payMethod === '1') {
                data.CostPerStop = 0;
                data.CostPerPiece = 0;
                data.CostPerDist = 0;
            } else if (payMethod === '2') {
                data.CostPerHour = 0;
                data.CostOtStart = '';
                data.CostPerOtHour = 0;
                data.CostPerPiece = 0;
                data.CostPerDist = 0;
            } else if (payMethod === '3') {
                data.CostPerHour = 0;
                data.CostOtStart = '';
                data.CostPerStop = 0;
                data.CostPerOtHour = 0;
                data.CostPerDist = 0;
            } else if (payMethod === '4') {
                data.CostPerHour = 0;
                data.CostOtStart = '';
                data.CostPerStop = 0;
                data.CostPerOtHour = 0;
                data.CostPerPiece = 0;
            }
            if (driverSpecialties.length > 0) {
                let s = [];
                driverSpecialties.forEach((ds) => {
                    if (ds.value) {
                        s.push(ds.value);
                    }
                });
                data.SkillSetProperties = s;
            } else {
                data.SkillSetProperties = [];
            }
            if (mode === 'New' || mode === 'Copy') {
                try {
                    await postDriver(workspace.value, data);

                    let logData = {
                        WorkspaceId: workspace.value,
                        ActionId: 1402,
                        Success: true,
                        Metadata: data.DriverName,
                    };
                    updateUserActivity(logData);

                    setEditDriverDialog(false);
                    setTarget({});
                    setDriverSpecialties([]);
                    setPayMethod('1');
                    const response = await getDrivers(workspace.value);
                    setData(response.data);
                    renderDriverGrid();
                } catch (e) {
                    let logData = {
                        WorkspaceId: workspace.value,
                        ActionId: 1402,
                        Success: false,
                        Metadata: data.DriverName,
                    };
                    updateUserActivity(logData);

                    const message = e.response.data.Message;
                    props.handleDialogOpen(message);
                }
            } else if (mode === 'Edit') {
                try {
                    await putDriver(workspace.value, data);

                    let logData = {
                        WorkspaceId: workspace.value,
                        ActionId: 1403,
                        Success: true,
                        Metadata: data.DriverName,
                    };
                    updateUserActivity(logData);

                    setEditDriverDialog(false);
                    setTarget({});
                    setDriverSpecialties([]);
                    setPayMethod('1');
                    const response = await getDrivers(workspace.value);
                    setData(response.data);
                    renderDriverGrid();
                    props.setRefreshRouteSet(true);
                } catch (e) {
                    let logData = {
                        WorkspaceId: workspace.value,
                        ActionId: 1403,
                        Success: false,
                        Metadata: data.DriverName,
                    };
                    updateUserActivity(logData);

                    const message = e.response.data.Message;
                    props.handleDialogOpen(message);
                }
            }
        }
    };

    const handleDeleteClose = async (option) => {
        if (option === 'CANCEL') {
            setDeleteDriverDialog(false);
            setTarget({});
        } else if (option === 'DELETE') {
            try {
                await deleteDriver(workspace.value, target);

                let logData = {
                    WorkspaceId: workspace.value,
                    ActionId: 1404,
                    Success: true,
                    Metadata: target.DriverName,
                };
                updateUserActivity(logData);

                const response = await getDrivers(workspace.value);
                setData(response.data);
                renderDriverGrid();
                props.setRefreshRouteSet(true);
            } catch (e) {
                let logData = {
                    WorkspaceId: workspace.value,
                    ActionId: 1404,
                    Success: false,
                    Metadata: target.DriverName,
                };
                updateUserActivity(logData);
                const message = e.response.data.Message;
                props.handleDialogOpen(message);
            }
            setDeleteDriverDialog(false);
            setTarget({});
        }
    };

    const updateForm = (e) => {
        let t = { ...target };
        t[e.target.name] = e.target.value;
        setTarget(t);
    };

    const handlePayModeChange = (e) => {
        setPayMethod(e.target.value);
    };

    const validForm = () => {
        if (!target.DriverName) {
            return false;
        }
        if (mode === 'New') {
            let duplicate = data.filter((d) => d.DriverName.toLowerCase() === target.DriverName.toLowerCase());
            if (duplicate.length > 0) {
                return false;
            }
        } else if (mode === 'Copy' || mode === 'Edit') {
            let duplicate = data.filter(
                (d) =>
                    d.DriverName.toLowerCase() === target.DriverName.toLowerCase() && target.DriverName.toLowerCase() !== editingName.toLowerCase(),
            );
            if (duplicate.length > 0) {
                return false;
            }
        }
        return true;
    };

    React.useEffect(() => {
        if (workspace && workspace.value !== 0) {
            getDrivers(workspace.value).then(
                (res) => {
                    setData(res.data);
                    renderDriverGrid();
                },
                (err) => {
                    setData([]);
                    renderDriverGrid();
                },
            );
            getSpecialties(workspace.value).then((res) => {
                let s = res.data.map((d) => {
                    return {
                        label: d.Specialty,
                        value: d.Id,
                    };
                });
                setSpecialtyList(s);
            });
        }
    }, [workspace]);

    useEffect(() => {
        if (workspace && workspace.value !== 0 && editDriverDialog) {
            getSpecialties(workspace.value).then((res) => {
                let s = res.data.map((d) => {
                    return {
                        label: d.Specialty,
                        value: d.Id,
                    };
                });
                setSpecialtyList(s);
            });
        }
    }, [editDriverDialog]);

    useEffect(() => {
        if (workspace && !manageSpecialtyDialogOpen) {
            getSpecialties(workspace.value).then((res) => {
                let s = res.data.map((d) => {
                    return {
                        label: d.Specialty,
                        value: d.Id,
                    };
                });
                setSpecialtyList(s);
            });
        }
    }, [manageSpecialtyDialogOpen]);

    useEffect(() => {
        let options = [...specialtyList];
        options.map((o) => {
            if (driverSpecialties.find((ds) => ds.value === o.value)) {
                o.isDisabled = true;
            } else {
                o.isDisabled = false;
            }
            return o;
        });
        setSpecialtyList(options);
    }, [driverSpecialties]);

    const addDriverSpecialty = () => {
        let newDriverSpecialties = [...driverSpecialties];
        const id = Math.random();
        if (newDriverSpecialties.filter((s) => s.Id === id).length === 0) {
            newDriverSpecialties.push({ Id: id, label: null, value: null });
        } else {
            newDriverSpecialties.push({ Id: -id, label: null, value: null });
        }
        setDriverSpecialties(newDriverSpecialties);
    };

    const updateDriverSpecialties = (e, i) => {
        let s = [...driverSpecialties];
        s.map((sl) => {
            if (sl.Id === i) {
                sl.label = e.label;
                sl.value = e.value;
            }
            return sl;
        });
        setDriverSpecialties(s);
    };

    const deleteDriverSpecialty = (id) => {
        let newDriverSpecialties = driverSpecialties.filter((x) => x.Id !== id);
        setDriverSpecialties(newDriverSpecialties);
    };

    return (
        <Grid
            item
            xs={12}
            style={{ zIndex: 100 }}
        >
            <div style={{ maxWidth: '100%', padding: '5px', height: isNaN(driverContainerHeight) ? '387px' : driverContainerHeight + 'px' }}>
                <div style={{ position: 'relative', height: '30px' }}>
                    <span style={{ fontSize: '18px', margin: '5px 10px' }}>{title}</span>
                    {props.siteMode !== 'PLANNER' && (
                        <IconButton
                            title={t('RoutePlans.Driver.Actions.New')}
                            color='primary'
                            style={{ position: 'absolute', top: '-5px', right: '25px', padding: 0, color: '#16a8a6' }}
                            onClick={() => handleNewDriver()}
                        >
                            <i className='RS_ADD' />
                        </IconButton>
                    )}
                </div>
                {renderDriverGrid()}
            </div>

            <EditDialogue
                editDriverDialog={editDriverDialog}
                mode={mode}
                title={'Driver'}
                updateForm={updateForm}
                target={target}
                defaultName={defaultName}
                payMethod={payMethod}
                handlePayModeChange={handlePayModeChange}
                handleEditClose={handleEditClose}
                editingName={editingName}
                validForm={validForm}
                driverSpecialties={driverSpecialties}
                addDriverSpecialty={addDriverSpecialty}
                deleteDriverSpecialty={deleteDriverSpecialty}
                setManageSpecialtyDialogOpen={setManageSpecialtyDialogOpen}
                specialtyList={specialtyList}
                updateDriverSpecialties={updateDriverSpecialties}
            />
            <DeleteDialogue
                deleteDriverDialog={deleteDriverDialog}
                mode={mode}
                title={'Driver'}
                target={target}
                handleDeleteClose={handleDeleteClose}
            />
            <ManagementSpecialtyDialog
                workspace={workspace}
                manageSpecialtyDialogOpen={manageSpecialtyDialogOpen}
                setManageSpecialtyDialogOpen={setManageSpecialtyDialogOpen}
            />
        </Grid>
    );
};

export default DriverTable;
