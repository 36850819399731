import React, { useState, useEffect, useContext } from 'react';
import { Grid, Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import RoutePlanTable from './RoutePlanTable';
import DriverTable from './DriverTable';
import TruckTable from './TruckTable';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { updateUserActivity } from '../../_actions/Global.actions';
import { currencyMaskOptions, floatMaskOptions } from '../../_helpers/common';
import { GlobalROContext } from '../common';

export const Manage = () => {
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState(false);
    const [refreshRouteSet, setRefreshRouteSet] = React.useState(false);

    const {
        workspace,
        siteMode,
        project,
        setRefreshRoutePlan,
        setShowWorkspaceList,
        setShowServiceDayList,
        setShowRoutePlanList,
        setShowWeekList,
        setShowFrequencyList,
        setShowServicePatternList,
    } = useContext(GlobalROContext);

    React.useEffect(() => {
        setShowWorkspaceList(true);
        setShowServiceDayList(false);
        setShowRoutePlanList(false);
        setShowWeekList(false);
        setShowFrequencyList(false);
        setShowServicePatternList(false);
    }, []);

    React.useEffect(() => {
        if (workspace && siteMode) {
            updateUserActivity({ ActionId: 1401, WorkspaceId: workspace.value, Mode: siteMode });
        }
    }, [workspace, siteMode]);

    const handleDialogOpen = (message) => {
        setOpen(true);
        setMessage(message);
    };

    const handleDialogClose = () => {
        setOpen(false);
    };

    const resetRouteSetRefresh = () => {
        setRefreshRouteSet(false);
    };

    return (
        <Grid
            id={'RO_MANAGE'}
            container
            style={{ marginTop: '10px' }}
        >
            <Grid
                container
                item
                md={4}
                style={{ paddingLeft: '5px' }}
            >
                <DriverTable
                    workspace={workspace}
                    siteMode={siteMode}
                    handleDialogOpen={handleDialogOpen}
                    setRefreshRouteSet={setRefreshRouteSet}
                />
                <hr style={{ width: '100%', border: '1px solid #999' }} />
                <TruckTable
                    workspace={workspace}
                    siteMode={siteMode}
                    handleDialogOpen={handleDialogOpen}
                    setRefreshRouteSet={setRefreshRouteSet}
                />
            </Grid>
            <Grid
                container
                item
                md={8}
                style={{ borderLeft: '2px solid #999' }}
            >
                <RoutePlanTable
                    workspace={workspace}
                    siteMode={siteMode}
                    project={project}
                    handleDialogOpen={handleDialogOpen}
                    handleDialogClose={handleDialogClose}
                    refreshRouteSet={refreshRouteSet}
                    resetRouteSetRefresh={resetRouteSetRefresh}
                    setRefreshRoutePlan={setRefreshRoutePlan}
                />
            </Grid>
            <AlertDialog
                open={open}
                message={message}
                handleDialogClose={handleDialogClose}
            />
        </Grid>
    );
};

export function AlertDialog(props) {
    return (
        <div>
            <Dialog
                open={props.open}
                onClose={() => props.handleDialogClose()}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                {/* <DialogTitle id="alert-dialog-title">
                    {"Error"}
                </DialogTitle> */}
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>{props.message}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => props.handleDialogClose()}
                        autoFocus
                    >
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export const CurrencyInput = ({ maskOptions, ...inputProps }) => {
    const currencyMask = createNumberMask({
        ...currencyMaskOptions,
        ...maskOptions,
    });

    return (
        <MaskedInput
            mask={currencyMask}
            {...inputProps}
            className={'currencyInput'}
        />
    );
};

CurrencyInput.defaultProps = {
    inputMode: 'numeric',
    maskOptions: {},
};

export const DurationInput = ({ maskOptions, ...inputProps }) => {
    return (
        <MaskedInput
            mask={[/\d/, /\d/, ':', /[0-5]/, /[0-9]/]}
            {...inputProps}
            className={'durationInput'}
        />
    );
};

export const ExpandedDurationInput = ({ maskOptions, ...inputProps }) => {
    const mask = [/[0-2]/, inputProps?.value && inputProps?.value[0] === '2' ? /[0-3]/ : /[0-9]/, ':', /[0-5]/, /[0-9]/, ':', /[0-5]/, /[0-9]/];
    return (
        <MaskedInput
            mask={mask}
            {...inputProps}
            className={'durationInput'}
        />
    );
};

function timeMask(value) {
    const chars = value.split('');
    const hours = [/[0-2]/, chars[0] === '2' ? /[0-4]/ : /[0-9]/];
    const minutes = [chars[0] === '2' && chars[1] === '4' ? /[0]/ : /[0-5]/, chars[0] === '2' && chars[1] === '4' ? /[0]/ : /[0-9]/];
    return hours.concat(':').concat(minutes);
}

export const TimeInput = ({ maskOptions, ...inputProps }) => {
    return (
        <MaskedInput
            mask={timeMask}
            {...inputProps}
            className={'durationInput'}
        />
    );
};

export const FloatInput = ({ maskOptions, ...inputProps }) => {
    const floatMask = createNumberMask({
        ...floatMaskOptions,
        ...maskOptions,
    });

    return (
        <MaskedInput
            mask={floatMask}
            {...inputProps}
            className={'floatInput'}
        />
    );
};

FloatInput.defaultProps = {
    inputMode: 'numeric',
    maskOptions: {},
};

export function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowSize;
}
